import React from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  VStack,
  Heading,
  Link,
  Image,
  extendTheme,
} from '@chakra-ui/react';

import Logo from './fleetmq_logo_white.svg';

const theme = extendTheme({
  colors: {
    brand: {
      white: "#f6f3ed",
      black: "#000000",
      grey: "#c0bdb7"
    }
  },
  fonts: {
    body: 'Roboto, sans-serif',
    heading: 'Roboto, sans-serif'
  }
});

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Box
        bg={"brand.black"}
        minH="100vh"
        p={5}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <VStack spacing={4} justify="center" align="center">
          <Image src={Logo} alt="FleetMQ Logo" boxSize="300px"/>
          <Heading
            color="brand.white"
            as="h1" size="4xl"
            sx={{
              backgroundClip: 'text',
              bgClip: 'text',
              bgGradient: 'linear(to-br, brand.white 20%, brand.grey 80%)',
              color: 'transparent'
            }}
          >
            FleetMQ
          </Heading>
          <Heading color="brand.grey" as="h2" size="xl">
            New Site Coming Soon... Follow us on <Link href="https://www.linkedin.com/company/abconnectio?utm_source=company_website&utm_medium=web&utm_campaign=linkedin_visit" isExternal color="#2865B9">LinkedIn</Link>
          </Heading>
          <Text color="brand.grey" fontSize="lg" textAlign="center">
            the ultimate middleware for your vehicle fleet.
          </Text>
        </VStack>
      </Box>
    </ChakraProvider>
  );
}

export default App;
